import { SidebarComponent } from './sidebar/sidebar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modulos J.C.RAMOS
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { RighbarComponent } from './righbar/righbar.component';
import { PipesModule } from '../modulos/pipes.module';
import { LoadingComponent } from '../components/common/loading/loading.component';
import { MaterialModule } from '../modulos/material.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ModalLegalComponent } from './modal-legal/modal-legal.component';
import { ProteccionDeDatosComponent } from './modal-legal/proteccion-de-datos/proteccion-de-datos.component';
import { FormularioRegistroComponent } from './modal-legal/formulario-registro/formulario-registro.component';
import { FormularioAyudaComponent } from './modal-legal/formulario-ayuda/formulario-ayuda.component';
import { TerminosCondicionesComponent } from './modal-legal/terminos-condiciones/terminos-condiciones.component';
import { CookieComponent } from './modal-legal/proteccion-de-datos/cookie/cookie.component';
import { TecnodretComponent } from '../components/common/tecnodret/tecnodret.component';
import { ClausulaAadidessIsesComponent } from './modal-legal/clausula-aadidess-ises/clausula-aadidess-ises.component';
import { BannerPlaystoreComponent } from './banner-playstore/banner-playstore/banner-playstore.component';
@NgModule({
declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    RighbarComponent,
    ModalLegalComponent,
    ProteccionDeDatosComponent,
    FormularioRegistroComponent,
    FormularioAyudaComponent,
    TerminosCondicionesComponent,
    CookieComponent,
    TecnodretComponent,
    ClausulaAadidessIsesComponent,
    BannerPlaystoreComponent,
],
imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    MatProgressBarModule,
    MaterialModule,
    ],
exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    RighbarComponent,
    ModalLegalComponent,
    FormularioRegistroComponent,
    FormularioAyudaComponent,
    ProteccionDeDatosComponent,
    TerminosCondicionesComponent,
    CookieComponent,
    TecnodretComponent,
    BannerPlaystoreComponent
    ]
})
export class SharedModule { }
