import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-botones-rapidos',
  templateUrl: './botones-rapidos.component.html',
  styleUrls: ['./botones-rapidos.component.scss']
})
export class BotonesRapidosComponent implements OnInit {

  showOverlay: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  openOverlay(): void {
    this.showOverlay = true;
  }

  closeOverlay(): void {
    this.showOverlay = false;
  }

  redirectToApp(): void {
    window.location.href = "https://play.google.com/store/apps/details?id=io.cipbyte.signomedico&pcampaignid=web_share";
  }

}
