<!-- ACTIVAR PARA AADIDESS SI LO SOLICITA ESTA EN VARIAS PAG (PROFILE / GYM Y HOME)-->
<!-- <app-cookiebot></app-cookiebot> -->
<div class="gym-home-area" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">

    <div *ngIf="showOverlay" class="overlay-banner" [ngClass]="{'fade-in': showOverlay, 'fade-out': !showOverlay}">
        <div class="overlay-content">
            <button class="btn-close" (click)="closeOverlay()"><i class="fas fa-times-circle"></i></button> <!-- Botón de cierre -->
            <h2>Atención afiliado</h2>
            <p>Descargue nuestra aplicación móvil para acceder a sus servicios de manera rápida y sencilla. Para ingresar, utilice su usuario y clave previamente activados. Si aún no tiene un usuario, regístrese en
                nuestra plataforma web.</p>
            <div>
                <button class="btn btn-primary" (click)="redirectToApp()">Descargar</button>
                <button class="btn btn-secondary" routerLink="/profile-authentication">Registrarse</button>
            </div>
        </div>
    </div>
    
    <div class="gym-banner-slides">
        <owl-carousel-o [options]="gymBannerSlides">
            <ng-template carouselSlide>
                <div class="gym-banner-item">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-content ">
                                    <img
                                        src="assets/img/logo-signomedico-2.png"
                                        alt="SignoMédico"
                                    >
                                    <h1>Bienvenido a Signo Médico</h1>
                                    <p>Ingrese a su cuenta o regístrese para acceder a nuestros servicios de forma rápida y sencilla.</p>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-12">
                                            <button
                                                routerLink="/profile-authentication"
                                                type="button"
                                                (click)="this.navigateRoot('/profile-authentication')"
                                                class="default-btn animate__animated animate__bounceInUp"
                                                ><i class="fas fa-user-circle"></i>Ingrese y elija su opción</button>
                                            </div>
                                    </div>
                                    <div class="container text-center mt-4">
                                        <mat-card class="custom-card">
                                            <mat-card-content>
                                                <div class="affiliate-attention">
                                                    <p class="mb-2">
                                                        Si ya ha registrado su usuario, le invitamos a descargar nuestra aplicación móvil.
                                                    </p>
                                                    <div class="d-flex justify-content-center">
                                                        <img 
                                                            src="assets/img/google-play-badge.png" 
                                                            alt="Descarga nuestra app en Google Play" 
                                                            class="img-fluid" 
                                                            style="max-width: 400px; display: block; margin: 0 auto;"
                                                            (click)="openOverlay()"
                                                        >
                                                    </div>                                                    
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                                                                                                                   
                                    <!-- <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <app-modal-legal [TC]="true" [PDP]="true"></app-modal-legal>
                                        </div>
                                    </div> -->
                                    <!-- TECNODRET LOGO -->
                                    <!--<app-tecnodret></app-tecnodret>-->
                                    <!-- FIN TECNODRET LOGO -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-image">
                                    <img
                                        src="assets/img/sliders/slider-1.png"
                                        alt="image"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="gym-banner-item">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-content">
                                    <img
                                        src="assets/img/logo-signomedico-2.png"
                                        alt="SignoMedico"
                                    >
                                    <h1>Bienvenido a Signo Médico</h1>
                                    <p>Ingrese a su cuenta o regístrese para acceder a nuestros servicios de forma rápida y sencilla.</p>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <button
                                                routerLink="/profile-authentication"
                                                type="button"
                                                w
                                                (click)="this.navigateRoot('/profile-authentication')"
                                                class="default-btn animate__animated animate__bounceInUp"
                                            ><i class="fas fa-user-circle"></i>Ingrese y elija su opción</button>
                                        </div>
                                    </div>
                                    <br>

                                    <!-- <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <app-modal-legal [TC]="true" [PDP]="true"></app-modal-legal>
                                        </div>
                                    </div> -->
                                    <!-- TECNODRET LOGO -->
                                    <app-tecnodret></app-tecnodret>
                                    <!-- FIN TECNODRET LOGO -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-image">
                                    <img
                                        src="assets/img/sliders/slider-2.png"
                                        alt="image"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>    
</div>

<!-- <app-banner-playstore></app-banner-playstore>  -->